import { Component, Input } from '@angular/core';
import { ProgressSpinnerComponent } from '../progress-spinner/progress-spinner.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-loading-component',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
    imports: [ProgressSpinnerComponent, TranslocoPipe]
})
export class LoadingComponent {
  @Input() message?: string;
  @Input() iconSize = '12rem';
}
