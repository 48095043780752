<div
  class="p-3 p-lg-4 w-100 d-flex flex-column flex-md-row size-{{
    size
  }} box-shadow"
  [ngStyle]="{ background: background }"
  [ngClass]="{ 'border-radius-4': borderRadius }"
>
  <div class="packex-container d-flex flex-column flex-md-row h-100">
    <div class="w-100 w-md-50 m-auto h-50 h-md-100">
      <ng-container *ngIf="size === 'xl'; else standard">
        <div
          class="d-flex align-items-center align-items-md-start flex-column justify-content-center h-100"
        >
          <h1
            class="mx-auto mx-md-0 mb-2 text-center text-md-start"
            [innerHtml]="title | transloco"
          ></h1>

          <packex-divider class="mx-auto mx-md-0"></packex-divider>

          <p class="subtitle-1 mx-auto mx-md-0 mb-4 text-center text-md-start">
            {{ content | transloco }}
          </p>

          <packex-button
            class="w-auto mx-auto mx-md-0"
            background="primary-dark"
            (buttonClick)="callback()"
          >
            {{ buttonText | transloco }}
          </packex-button>
        </div>
      </ng-container>

      <ng-template #standard>
        <h4 class="mb-2">{{ title | transloco }}</h4>

        <p class="mb-3">{{ content | transloco }}</p>

        <packex-button
          class="w-auto"
          type="secondary"
          color="primary-dark"
          (buttonClick)="callback()"
        >
          {{ buttonText | transloco }}
        </packex-button>
      </ng-template>
    </div>

    <div
      class="w-100 w-md-50 m-auto h-50 h-md-100 d-block d-md-flex align-items-center justify-content-center"
    >
      <img [src]="asset" alt="image" class="m-auto" />
    </div>
  </div>
</div>
