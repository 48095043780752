import { Component, Input } from '@angular/core';
import { NgStyle, NgClass, NgIf } from '@angular/common';
import { DividerComponent } from '../ui/divider/divider.component';
import { ButtonComponent } from '../ui/button/button.component';
import { TranslocoPipe } from '@jsverse/transloco';

@Component({
    selector: 'packex-advertise-banner',
    templateUrl: './advertise-banner.component.html',
    styleUrls: ['./advertise-banner.component.scss'],
    imports: [NgStyle, NgClass, NgIf, DividerComponent, ButtonComponent, TranslocoPipe]
})
export class AdvertiseBannerComponent {
  @Input() asset = 'Provide a title';
  @Input() title = 'Provide a title';
  @Input() content = 'Provide a text';
  @Input() background? = 'white';
  @Input() buttonText = 'Button';
  @Input() borderRadius = true;

  @Input() size: 'xl' | 'l' | 's' = 'l';

  @Input() callback = () => {
    alert('Please provide a function');
  };
}
